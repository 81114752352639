@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply tw-w-full;
    @apply tw-h-full;
    @apply tw-antialiased;
    @apply tw-leading-[1.6];
    @apply tw-bg-base-lv1;
    @apply tw-text-main-100;
    @apply tw-break-all;
    font-family:
      'Hiragino Sans',
      'Hiragino Kaku Gothic ProN',
      'Noto Sans JP',
      'Noto Sans CJK JP',
      'BIZ UDPGothic',
      Meiryo,
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
  }

  @media screen(tablet) {
    html,
    body {
      font-size: 18px;
    }
  }

  a {
    text-underline-offset: 0.25em;
  }

  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img,
  ul,
  li,
  header,
  footer,
  nav,
  section,
  article,
  a,
  p,
  blockquote,
  span,
  header,
  footer,
  nav,
  section,
  article,
  input,
  textarea,
  figure,
  figcaption,
  main,
  pre {
    @apply tw-break-words;
  }
}

@layer components {
  .rich-text-container {
    & > :first-child {
      margin-top: 0 !important;
    }

    @apply tw-text-base1;
    @apply tw-leading-[1.8];

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply tw-text-main-100;
      @apply tw-font-bold;
      @apply tw-mt-[1.5rem];
      @apply tw-mx-auto;
      @apply tw-mb-[1rem];
      @apply tw-leading-[1.4];
      @apply tw-w-full;
      @apply tw-halt;
    }

    h1 {
      @apply tw-text-h2;
    }

    h2 {
      @apply tw-text-h3;
    }
    h3 {
      @apply tw-text-h4;
    }
    h4,
    h5,
    h6 {
      @apply tw-text-h5;
    }

    h1 + *,
    h2 + *,
    h3 + *,
    h4 + *,
    h5 + *,
    h6 + * {
      margin-top: 0 !important;
    }

    strong {
      @apply tw-text-main-100;
      @apply tw-font-bold;
    }

    a {
      @apply tw-underline;
    }

    p {
      @apply tw-text-main-80;
      @apply tw-mt-[0.5rem];
      @apply tw-min-h-[0.5rem];
      font-size: inherit;

      &.block-img {
        @apply tw-mt-[1rem];
        @apply tw-mx-auto;
        @apply tw-w-full;
        @apply tw-max-w-[400px];

        img {
          @apply tw-w-full;
        }
      }
    }

    ul,
    ol {
      @apply tw-my-[1.5rem];
      @apply tw-mx-auto;
      @apply tw-pl-[1.5rem];

      li {
        font-size: inherit;
        list-style-position: outside;

        &:not(:first-child) {
          @apply tw-mt-[0.5rem];
        }
      }
    }

    ul li {
      list-style-type: disc;
    }

    ol li {
      list-style-type: decimal;
    }

    strong {
      @apply tw-font-bold;
    }

    iframe {
      @apply tw-w-full;
    }

    .center-align {
      @apply tw-block;
      @apply tw-text-center;
      @apply tw-mt-[0.5rem];
    }

    .color-warning {
      @apply tw-text-caution-lv1;
      @apply tw-font-bold;
    }

    .color-theme {
      color: rgba(73, 95, 124, 1);
      @apply tw-font-bold;
    }

    .font-note {
      @apply tw-text-base2;
      @apply tw-text-main-40;
      @apply tw-block;

      a {
        font-size: inherit;
      }
    }

    .font-underline {
      @apply tw-underline;
    }
  }
}
